import * as React from "react"
import Layout from "../components/layout";
import Hero from "../components/hero";

import {Container, Typography} from "@mui/material";

// markup
const PrivacyPolicyPage = () => {
    return (
        <Layout title={"Privacy policy"}>
            <main>
                <Hero title={"Privacy Policy"}
                      subtitle={""} />

                <Container style={{paddingTop: '4em'}}>
                    <Typography variant={"h5"} marginTop={3}>JURYBOX TECHNOLOGIES, LLC PRIVACY POLICY</Typography>

                    Jurybox Technologies, LLC ("Jurybox") offers this privacy policy (the "Policy") to demonstrate our firm commitment to privacy. This Policy discloses our practices for our Website’s information gathering and dissemination.

                    <Typography variant={"h5"} marginTop={3}>Your IP Address</Typography>

                    Pursuant to our Policy, we use your IP address to help diagnose problems with our server, and to administer our Website. Your IP address is used to gather broad demographic information. This site automatically collects other information such as browser type, platform, and connection speed.

                    <Typography variant={"h5"} marginTop={3}>Links</Typography>

                    Our Website contains links to other sites. Jurybox is not responsible for the privacy practices or the content of such Websites.

                    <Typography variant={"h5"} marginTop={3}>User Information</Typography>

                    Users provide a name and email address on signup and may receive a welcome email after signing up. Further email communications may follow to provide updates to the product or services. We may solicit users to voluntarily provide additional contact information such as a phone number. We use this contact information to facilitate communications with users. The user’s contact information may be also used to contact the user regarding their specific request or to send administrative notices regarding their use of the software. We may collect additional information regarding your preferences or demographics to help improve our user experience. All collected data is for internal use only and is never shared with third parties.

                    <Typography variant={"h5"} marginTop={3}>Security</Typography>

                    This site has instituted security measures to protect the loss, misuse and alteration of the information under our control. Specifically, (i) our server and database use secure communications (SSL/TLS); (ii) our server and database are password protected and hosted securely; (iii) only authorized employees have access to data stored in database; (iv) our web security is under on-going scrutiny and review to ensure that it meets high security standards; and (v) collected information is never shared with third parties.

                    <Typography variant={"h5"} marginTop={3}>Cookies</Typography>

                    Jurybox uses cookies for session maintenance when a user logs into their account.

                    <Typography variant={"h5"} marginTop={3}>Disclaimer</Typography>

                    We may disclose personal information when required by law or in the good-faith belief that such action is necessary in order to conform to the edicts of the law or comply with a legal process served on our Website.

                    <Typography variant={"h5"} marginTop={3}>Notification of Changes</Typography>

                    Information collected will only be used in a manner differing from this statement if active email consent is provided by the user. Any changes to this statement will be communicated to the user via email.

                    <Typography variant={"h5"} marginTop={3}>Contacting Jurybox</Typography>

                    If you have any questions about this privacy statement, the practices of this site, or your dealings with this website, you can contact us at support@juryboxapp.com.

                    <br/><br/>

                    UPDATED: May 15, 2020

                </Container>
            </main>
        </Layout>
    )
}

export default PrivacyPolicyPage
